interface SeoTagsProps {
  title: string
  description: string
  image: string
}

export const SeoTags = ({ description, title, image }: SeoTagsProps) => (
  <>
    <meta name="description" content={description} />
    <meta property="og:locale" content="en_US" />

    {/* <!-- Facebook Meta Tags --> */}
    <meta property="og:url" content="https://useintegral.com/" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />

    {/* <!-- Twitter Meta Tags --> */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta property="twitter:domain" content="https://useintegral.com/" />
    <meta property="twitter:url" content="https://useintegral.com/" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />
  </>
)
