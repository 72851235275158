import { Stack, Box, Text } from "@chakra-ui/react"

import { HubspotContactFormWidget } from "./HubspotContactFormWidget"

const BookDemoSection = ({ isWhiteBackground = false }) => {
  return (
    <Box backgroundColor={isWhiteBackground ? "inherit" : "var(--website-color-background-base)"} paddingTop="64px" paddingBottom="0px">
      <Stack maxWidth="var(--website-width-max)" width={["80%", "90%"]} margin="auto" direction={["column", "row"]} spacing={["40px", "0px"]}>
        <Box flex={0.5} justifyContent={["center", "center", "flex-start"]} paddingRight={["0px", "40px"]}>
          <Text
            color={isWhiteBackground ? "inherit" : "var(--website-color-h1)"}
            textAlign={["center", "center", "left"]}
            fontSize="36px"
            lineHeight="45px"
            fontWeight={["400", "600"]}
            width={["100%", "70%"]}
            paddingBottom="12px"
            marginTop={["0px", "180px"]}
          >
            Got 20 Minutes? See for Yourself
          </Text>
          <Text
            color="var(--website-color-footer-link-base)"
            textAlign={["center", "center", "left"]}
            fontSize={["var(--website-font-size-p-small)", "var(--website-font-size-p)"]}
            fontStyle="normal"
            fontWeight="400"
            width={["100%", "90%"]}
          >
            Schedule a conversation with Integral’s Founder & CEO, Shubh Sinha, to learn more about streamlined expert certifications through easy-to-use, secure, automated software.
          </Text>
        </Box>
        <Box flex={0.7}>
          <HubspotContactFormWidget />
        </Box>
      </Stack>
    </Box>
  )
}

export default BookDemoSection
