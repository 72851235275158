import { useEffect, useRef, useState } from "react"

import { Box, Flex, Image, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from "@chakra-ui/react"

export const CAPABILITIES = [
  {
    title: "Collaboration",
    description: "Certifications benefit from centralized collaboration across multiple stakeholders.",
    src: "https://imgix.cosmicjs.com/23b29f00-c43a-11ee-a799-ff291b5cb9b0-FinCollaboration-Visual.png",
    icon: "https://imgix.cosmicjs.com/c1b30210-aa6f-11ee-b417-db331415685f-capabilities_icon_collaboration.png",
  },
  {
    title: "Speed",
    description: "Effectively establish stakeholder assignments, and assign due dates.",
    src: "https://imgix.cosmicjs.com/23a53180-c43a-11ee-a799-ff291b5cb9b0-FinSpeed-Visual.png",
    icon: "https://imgix.cosmicjs.com/7c00c0e0-aa6f-11ee-b417-db331415685f-capabilities_icon_speed.png",
  },
  {
    title: "Flexibility",
    description: "Use Integral’s prebuilt workflows, or create custom tasks.",
    src: "https://imgix.cosmicjs.com/23af91c0-c43a-11ee-a799-ff291b5cb9b0-FinFlexibility.png",
    icon: "https://imgix.cosmicjs.com/7bfd3e70-aa6f-11ee-b417-db331415685f-capabilities_icon_flexibility.png",
  },
  {
    title: "Notifications",
    description: "Keep initiatives on track with system notifications for pending tasks.",
    src: "https://imgix.cosmicjs.com/23a0c4b0-c43a-11ee-a799-ff291b5cb9b0-FinNotifications.png",
    icon: "https://imgix.cosmicjs.com/7bff6150-aa6f-11ee-b417-db331415685f-capabilities_icon_notifications.png",
  },
]

const CapabilitiesSwitcher = () => {
  const [tabIndex, setTabIndex] = useState(0)

  // Switch tabs every 5 seconds
  const capabilitiesTimer = useRef<NodeJS.Timeout | undefined>()

  useEffect(() => {
    capabilitiesTimer.current = setInterval(() => {
      setTabIndex((tabIndex) => (tabIndex === 3 ? 0 : tabIndex + 1))
    }, 5000)

    return () => clearInterval(capabilitiesTimer.current)
  }, [])

  return (
    <Tabs index={tabIndex} orientation="vertical" variant="unstyled">
      <TabList width="348px">
        {CAPABILITIES.map((capability, index) => {
          let borderColor = "var(--website-color-stroke-link)"

          if (tabIndex === index) {
            borderColor = "var(--website-color-stroke-link-hover)"
          }

          return (
            <Tab
              key={index}
              onMouseEnter={() => {
                clearInterval(capabilitiesTimer.current)
                setTabIndex(index)
              }}
              style={{
                borderLeft: "4px solid #E3D3FF",
                borderColor: borderColor,
              }}
            >
              <VStack alignItems="baseline" textAlign="left" marginTop="12px" marginBottom="12px" marginLeft="12px" fontSize="var(--website-font-size-p-small)">
                <Text color="var(--website-color-h1-on-light)">{capability.title}</Text>
                <Text color="var(--website-color-p-on-light)" opacity="0.8" fontWeight="400">
                  {capability.description}
                </Text>
              </VStack>
            </Tab>
          )
        })}
      </TabList>
      <TabPanels>
        {CAPABILITIES.map((capability, index) => (
          <TabPanel key={index}>
            <Flex>
              <Box marginRight="-50px" marginLeft="-50px">
                <Image src={capability.src} alt="Screenshot of platform" />
              </Box>
            </Flex>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

const CapabilitiesDesktop = () => {
  return (
    <>
      <VStack justifyContent="space-around" width="100%">
        <Text
          fontSize={["var(--website-font-size-h1-mobile)", "var(--website-font-size-h1-mobile)", "var(--website-font-size-h1)"]}
          textAlign="center"
          lineHeight="102%"
          maxWidth="800px"
          marginBottom="64px"
          marginTop="72px"
        >
          Capabilities That Retain Data Fidelity for Your Use Cases
        </Text>
        <Box width="100%">
          <CapabilitiesSwitcher />
        </Box>
      </VStack>
    </>
  )
}

export default CapabilitiesDesktop
